import { gql, useMutation, useLazyQuery } from '@apollo/client';

const getClassesQuery = gql`
  query ($filters: ClassFilterInput) {
    classes(limit: 0, skip: 0, filters: $filters) {
      data {
        id
        name
        course {
          id
          name
        }
      }
    }
  }
`;

const getClassesOfEmployees = gql`
  query ($filters: ClassFilterInput) {
    classes(limit: 100, skip: 0, filters: $filters) {
      data {
        value: id
        label: name
      }
    }
  }
`;

export const GITHUB_ISSUE_INFO_CARD_FRAGMENT = gql`
  fragment fullName on data {
    firstName
    lastName
  }
`;

const getStudentsQuery = gql`
  query(
    $filters: InstituteStudentFilterInput
    $limit: Int
    $sort: PInstituteStudentSortInput
    $skip: Int
  ) {
    instituteStudents(
      filters: $filters
      limit: $limit
      sort: $sort
      skip: $skip
    ) {
      totalCount
      hasNextPage
      data {
        id
        email
        serialNo
        userName
        name: firstName
        fullName
        division
        gender
        rollNo
        grNo
        serialNo
        scrollRefNo
        averageMarks
        accountId
        subjectGroup {
          id
          name
        }
        status
        draftTotalOnboardingCompletion
        totalOnboardingCompletion
        verificationStatus {
          expiresAt
        }
        fees {
          installmentPayable
          feeComposition
        }
        batch {
          divisionDetails {
            division
          }
        }
        academic {
          id
          payments
          class {
            name
          }
        }
      }
    }
  }
`;

const getUnassignedDivStudentsQuery = gql`
  query(
    $filters: InstituteStudentFilterInput
    $limit: Int
    $sort: PInstituteStudentSortInput
    $skip: Int
  ) {
    instituteStudents(
      filters: $filters
      limit: $limit
      sort: $sort
      skip: $skip
    ) {
      data {
        id
        email
        fullName
        division
        gender
        averageMarks
        subjectGroup {
          id
          name
        }
      }
    }
  }
`;

const inviteStudentMutation = gql`
  mutation ($payload: InviteInstituteStudentInput!) {
    inviteStudent(payload: $payload)
  }
`;

const sendInviteMutation = gql`
  mutation ($invite: InviteInput!) {
    sendInvite(invite: $invite)
  }
`;

const sendOnboardingReminderMutation = gql`
  mutation (
    $userIds: [String!]
    $userType: UserType!
    $type: BroadcastType!
    $batch: String
    $division: String
    $recipient: RecipientType
  ) {
    broadcastMail(
      userIds: $userIds
      userType: $userType
      type: $type
      batch: $batch
      division: $division
      recipient: $recipient
    ) {
      message
      status
    }
  }
`;

const importStudentMutation = gql`
  mutation ($file: Upload!, $batchId: String!, $division: String!, $subGrpId: String!) {
    studentBulkInvite(file: $file, batchId: $batchId, division: $division, subGrpId: $subGrpId) {
      sent
      message
      notSent
      errors
    }
  }
`;

const deleteStudentMutation = gql`
  mutation ($academicId: String!) {
    removeAcademicDetail(academicId: $academicId)
  }
`;

const applyFeeCompositionMutation = gql`
  mutation($studentId: String!, $compositionId: String!, $academicId: String!) {
    applyFeeCompositionToStudent(
      studentId: $studentId
      compositionId: $compositionId
      academicId: $academicId
    ) {
      message
      status
    }
  }
`;

const generateBonafideCertificateMutation = gql`
  mutation($studentId: String!) {
    generateStudentBonafied(studentId: $studentId) {
      message
      status
    }
  }
`;

const requestLeavingCertificate = gql`
  query($studentId: String!) {
    requestLeavingCertificate(studentId: $studentId) {
      academic {
        grNo
      }
      lastQualificationName
      subjects
      religion
      caste
      fullName
      motherName
      fatherName
      placeOfBirth
      qualifiedForHigherClass
      generalConduct
      dob
      instituteName
      reasonForLeaving
      udiseNo
      dateOfAdmission
      nationality
    }
  }
`;

const generateLeavingCertificateMutation = gql`
  mutation($input: LeavingCertificateDetailInput!, $studentId: String!) {
    generateLeavingCertificate(input: $input, studentId: $studentId) {
      id
    }
  }
`;

const basicStudentDataQuery = gql`
  query($filters: InstituteStudentFilterInput) {
    instituteStudents(
      filters: $filters
      limit: 0
      sort: { fullName: ASCENDING }
    ) {
      totalCount
      data {
        id
        fullName
        gender
        rollNo
      }
    }
  }
`;

const generateIDCardMutation = gql`
  mutation($studentId: String!) {
    generateIDCard(studentId: $studentId) {
      status
    }
  }
`;

const generateIDCardsMutation = gql`
  mutation($batchId: String!, $division: String!) {
    generateIDCards(batchId: $batchId, division: $division) {
      status
      message
    }
  }
`;

const generatePrintableIDCardsMutation = gql`
  mutation($batchId: String!, $division: String!) {
    generatePrintableIDCardsPdf(batchId: $batchId, division: $division) {
      status
      message
    }
  }
`;

const generatePrintableLibraryCardsMutation = gql`
  mutation($batchId: String!, $division: String!) {
    generatePrintableLibraryCardsPdf(batchId: $batchId, division: $division) {
      status
      message
    }
  }
`;

export const reconcileStudentPaymentsMutation = gql`
  mutation ($studentId: String!) {
    reconcileStudentsPayment(studentId: $studentId) {
      message
      status
    }
  }
`;

export const lastStudentReconcilePaymentRequest = gql`
  query ($studentId: String!) {
    getStudentLastPaymentReconcileRequest(studentId: $studentId) {
      createdAt
      status
      errMessage
    }
  }
`;

const generatePaymentReceiptsMutation = gql`
  mutation($batchId: String!, $division: String!) {
    generatePaymentReceipts(batchId: $batchId, division: $division) {
      status
      message
    }
  }
`;

const generatePaymentReceiptMutation = gql`
  mutation($academicId: String) {
    generatePaymentReceipt(academicId: $academicId) {
      status
      message
    }
  }
`;

const searchStudentsQuery = gql`
  query($limit: Int, $search: SearchStudentInput!, $skip: Int, $sort: PInstituteStudentSortInput) {
    searchStudents(limit: $limit, search: $search, skip: $skip, sort: $sort) {
      data {
        id
        accountId
        status
        verificationStatus {
          expiresAt
        }
        personalDetails {
          fullName
          email
          mobileNumber
        }
        academic {
          batch {
            id
            name
            alias
          }
          class {
            alias
            course {
              name
              alias
            }
          }
          division
        }
      }
      hasNextPage
      totalCount 
    }
  }
`;

const getStudentsListQuery = gql`
  query (
    $filters: InstituteStudentFilterInput
    $sort: PInstituteStudentSortInput
  ) {
    instituteStudents(filters: $filters, limit: 0, sort: $sort, skip: 0) {
      totalCount
      hasNextPage
      data {
        id
        email
        name: firstName
        fullName
        gender
        rollNo
      }
    }
  }
`;

const getDocumentsStatusGenerationQuery = gql`
  query ($batchId: String!, $division: String!, $type: RequestType!) {
    getBatchDocumentsBulkGenerationStatus(batchId: $batchId, division: $division, type: $type) {
      total
      pending
      completed
      failed
      failedRequests {
        errMessage
        moduleId
      }
    }
  }
`;

const getBatchDocumentsGenerationStatusQuery = gql`
  query ($batchId: String!, $division: String!, $type: RequestType!) {
    getBatchDocumentsGenerationStatus(batchId: $batchId, division: $division, type: $type) {
      createdAt
      errMessage
      moduleId
      status
      type
      updatedAt
    }
  }
`;

export { getClassesQuery, getStudentsQuery, getClassesOfEmployees, requestLeavingCertificate, getStudentsListQuery, getDocumentsStatusGenerationQuery, getBatchDocumentsGenerationStatusQuery };

export function useGetClasses() {
  const [getInstituteClasses, { loading, data: classesData, error: classesError }] =
    useLazyQuery(getClassesQuery);
  return { getInstituteClasses, classesData, classesError, loading };
}

export function useInviteStudent() {
  const [inviteIntituteStudent, { data: inviteStudentData, error: inviteEmployeeError }] =
    useMutation(inviteStudentMutation);
  return { inviteIntituteStudent, inviteStudentData };
}

export function useImportStudent() {
  const [studentBulkInvite, { data: studentBulkInviteData, error: inviteEmployeeError }] =
    useMutation(importStudentMutation);
  return { studentBulkInvite, studentBulkInviteData };
}

export function useSendInvite() {
  const [sendInvite, { data: sendInviteData, error: sendInviteError }] =
    useMutation(sendInviteMutation);
  return { sendInvite, sendInviteData };
}

export function useSendOnboardingReminder() {
  const [sendOnboardingReminder, { data: sendOnboardingReminderData, error: sendOnboardingReminderError }] =
    useMutation(sendOnboardingReminderMutation);
  return { sendOnboardingReminder, sendOnboardingReminderData };
}

export function useDeleteStudent() {
  const [deleteStudent, { error: deleteStudentError }] =
    useMutation(deleteStudentMutation);
  return { deleteStudent, deleteStudentError };
}

export function useGetInstituteStudents() {
  const [
    getInstituteStudents,
    { loading: instituteStudentsLoading, data: instituteStudentsData, error: instituteStudentsError, refetch: refetchInstituteStudents },
  ] = useLazyQuery(getStudentsQuery, { fetchPolicy: 'network-only' });
  return { getInstituteStudents, instituteStudentsData, instituteStudentsError, instituteStudentsLoading, refetchInstituteStudents };
}

export function useGetUnassignedDivStudents() {
  const [
    getUnassignedDivStudents,
    { loading, data, refetch },
  ] = useLazyQuery(getUnassignedDivStudentsQuery, { fetchPolicy: 'network-only' });
  return { getUnassignedDivStudents, data, loading, refetch };
}

export function useGetStudentsList() {
  const [
    getStudentsList,
    { loading: studentListLoading, data: studentListData, error: studentListError, refetch: studentListRefetch },
  ] = useLazyQuery(getStudentsListQuery, { fetchPolicy: "network-only" });
  return { getStudentsList, studentListData, studentListError, studentListLoading, studentListRefetch };
}

export function useGetBasicStudentData() {
  const [
    getBasicStudentData,
    { loading: loadingBasicStudentData, data: dataBasicStudent },
  ] = useLazyQuery(basicStudentDataQuery, { fetchPolicy: "network-only" });
  return { getBasicStudentData, loadingBasicStudentData, dataBasicStudent };
}

export function useApplyFeeComposition() {
  const [applyFeeComposition, { data: applyFeeCompositionData, error: applyFeeCompositionError }] = useMutation(applyFeeCompositionMutation);
  return { applyFeeComposition, applyFeeCompositionData, applyFeeCompositionError };
};

export function useGenerateBonafideCertificate() {
  const [generateBonafideCertificate, { data: generateBonafideCertificateData, error: generateBonafideCertificateError }] =
    useMutation(generateBonafideCertificateMutation);
  return { generateBonafideCertificate, generateBonafideCertificateData };
}

export function useGenerateLeavingCertificate() {
  const [generateLeavingCertificate, { data: generateLeavingCertificateData, error: generateLeavingCertificateError }] =
    useMutation(generateLeavingCertificateMutation);
  return { generateLeavingCertificate, generateLeavingCertificateData };
}

export function useGenerateIDCard() {
  const [generateIDCard, { data: generateIDCardData, error: generateIDCardError }] =
    useMutation(generateIDCardMutation);
  return { generateIDCard, generateIDCardData };
}

export function useGenerateAllIDCards() {
  const [generateAllIdCards, { data: generateAllIdCardsData, error: generateIDCardError }] =
    useMutation(generateIDCardsMutation);
  return { generateAllIdCards, generateAllIdCardsData };
}

export function useReconcileStudentPayments() {
  const [reconcileStudentPayment, { data: reconcileStudentPaymentData, error: reconcileStudentPaymentError }] =
    useMutation(reconcileStudentPaymentsMutation);
  return { reconcileStudentPayment, reconcileStudentPaymentData, reconcileStudentPaymentError };
}

export function useGeneratePaymentReceipts() {
  const [generatePaymentReceipts, { data: generatePaymentReceiptsData, error: generatePaymentReceiptsError }] =
    useMutation(generatePaymentReceiptsMutation);
  return { generatePaymentReceipts, generatePaymentReceiptsData };
}

export function useGeneratePaymentReceipt() {
  const [generatePaymentReceipt, { data: generatePaymentReceiptData, error: generatePaymentReceiptError }] =
    useMutation(generatePaymentReceiptMutation);
  return { generatePaymentReceipt, generatePaymentReceiptData };
}

export function useSearchStudents() {
  const [
    searchStudents,
    { loading: searchStudentsLoading, data: searchStudentsData, error: searchStudentsError, refetch: refetchSearchStudents },
  ] = useLazyQuery(searchStudentsQuery, { fetchPolicy: 'network-only' });
  return { searchStudents, searchStudentsData, searchStudentsError, searchStudentsLoading, refetchSearchStudents };
}

export function useGetDocumentsStatusGeneration() {
  const [
    getDocumentsStatusGeneration,
    { loading: documentsStatusGenerationLoading, data: documentsStatusGenerationData, error: documentsStatusGenerationError, refetch: refetchDocumentsStatusGeneration },
  ] = useLazyQuery(getDocumentsStatusGenerationQuery, { fetchPolicy: 'network-only' });
  return { getDocumentsStatusGeneration, documentsStatusGenerationData, documentsStatusGenerationError, documentsStatusGenerationLoading, refetchDocumentsStatusGeneration };
}

export function useGeneratePrintableIDCards() {
  const [generatePrintableIdCards, { data: generatePrintableIdCardsData, error: generatePrintableIdCardsError }] =
    useMutation(generatePrintableIDCardsMutation);
  return { generatePrintableIdCards, generatePrintableIdCardsData };
}


export function useGeneratePrintableLibraryCards() {
  const [generatePrintableLibraryCards, { data: generatePrintableLibraryCardsData, error: generatePrintableLibraryCardsError }] =
    useMutation(generatePrintableLibraryCardsMutation);
  return { generatePrintableLibraryCards, generatePrintableLibraryCardsData };
}